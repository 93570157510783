import {useEffect, useState} from "react";
import {CheckoutAddressForm} from "./CheckoutAddressForm";
import {saveCheckoutAddress} from "../../../../lib/api/checkoutApi";
import {FormattedMessage, useIntl} from "react-intl";
import {CheckoutAddressValues} from "../../model/CheckoutAddressValuesModel";
import {checkoutActions} from "../../checkoutSlice";
import {useAppDispatch} from "../../../../lib/redux/reduxHooks";

export interface CheckoutAddressRequest {
    billingAddress: CheckoutAddressValues;
    shippingAddress: CheckoutAddressValues;
    shippingAddressEqualsBillingAddress: boolean;
    customerAddresses?: CheckoutAddressValues[];
    hasShippableFulfillmentGroups?: boolean;
}

export interface CheckoutSectionAddressExpandedProps extends CheckoutAddressRequest {
    setIsSectionOpen: (isSectionOpen: boolean) => void;
    customerAddresses: CheckoutAddressValues[]
}

const notDeliverablePlzs = ['3825', '3823', '3943', '3987', '3992', '3906', '3920', '7562', '7563'];

export const isValidAddress = (address: string): boolean => {
    address = address.trim();
    const onlyNumberWithOptionalLetters = /^\d+\p{L}*$/u;
    const startsWithLetter = /^\p{L}/u;
    const containsSpaceBeforeNumber = /\s\d+/;
    const endsWithNumberAndOptionalLetter = /\d+\s*\p{L}*$/u;

    return !onlyNumberWithOptionalLetters.test(address) &&
        startsWithLetter.test(address) &&
        containsSpaceBeforeNumber.test(address) &&
        endsWithNumberAndOptionalLetter.test(address) &&
        address.length <= 100;
}

export function CheckoutSectionAddressExpanded(props: CheckoutSectionAddressExpandedProps) {

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [shippingAddressEqualsBillingAddress, setShippingAddressEqualsBillingAddress] = useState<boolean>(props.shippingAddressEqualsBillingAddress);

    const [shippingSalutation, setShippingSalutation] = useState<string>(props.shippingAddress.salutation ? props.shippingAddress.salutation : '');
    const [shippingCompany, setShippingCompany] = useState<string>(props.shippingAddress.company);
    const [shippingFirstName, setShippingFirstName] = useState<string>(props.shippingAddress.firstName);
    const [shippingLastName, setShippingLastName] = useState<string>(props.shippingAddress.lastName);
    const [shippingAddress, setShippingAddress] = useState<string>(props.shippingAddress.address);
    const [shippingAddress2, setShippingAddress2] = useState<string>(props.shippingAddress.address2);
    const [shippingPlz, setShippingPlz] = useState<string>(props.shippingAddress.plz);
    const [shippingCity, setShippingCity] = useState<string>(props.shippingAddress.city);
    const [shippingPhone, setShippingPhone] = useState<string>(props.shippingAddress.phone);

    const [billingSalutation, setBillingSalutation] = useState<string>(props.billingAddress.salutation ? props.billingAddress.salutation : '');
    const [billingCompany, setBillingCompany] = useState<string>(props.billingAddress.company);
    const [billingFirstName, setBillingFirstName] = useState<string>(props.billingAddress.firstName);
    const [billingLastName, setBillingLastName] = useState<string>(props.billingAddress.lastName);
    const [billingAddress, setBillingAddress] = useState<string>(props.billingAddress.address);
    const [billingAddress2, setBillingAddress2] = useState<string>(props.billingAddress.address2);
    const [billingPlz, setBillingPlz] = useState<string>(props.billingAddress.plz);
    const [billingCity, setBillingCity] = useState<string>(props.billingAddress.city);
    const [billingPhone, setBillingPhone] = useState<string>(props.billingAddress.phone);

    const [shippingFirstNameError, setShippingFirstNameError] = useState<string>('');
    const [shippingLastNameError, setShippingLastNameError] = useState<string>('');
    const [shippingAddressError, setShippingAddressError] = useState<string>('');
    const [shippingPlzError, setShippingPlzError] = useState<string>('');
    const [shippingCityError, setShippingCityError] = useState<string>('');
    const [shippingPhoneError, setShippingPhoneError] = useState<string>('');
    const [shippingSalutationError, setShippingSalutationError] = useState<string>('');

    const [billingFirstNameError, setBillingFirstNameError] = useState<string>('');
    const [billingLastNameError, setBillingLastNameError] = useState<string>('');
    const [billingAddressError, setBillingAddressError] = useState<string>('');
    const [billingPlzError, setBillingPlzError] = useState<string>('');
    const [billingCityError, setBillingCityError] = useState<string>('');
    const [billingPhoneError, setBillingPhoneError] = useState<string>('');
    const [billingSalutationError, setBillingSalutationError] = useState<string>('');

    useEffect(() => {
        setShippingAddressEqualsBillingAddress(props.shippingAddressEqualsBillingAddress);
    }, [props.shippingAddressEqualsBillingAddress]);

    function isNumeric(input: string) {
        return /^\+?\d+$/.test(input);
    }

    const saveAddresses = async () => {

        let shippingValid = false;
        let billingValid = true;

        if(props.hasShippableFulfillmentGroups) {
            shippingValid = validateShippingAddress();
            if (!shippingAddressEqualsBillingAddress) {
                billingValid = validateBillingAddress();
            }

            if(notDeliverablePlzs.includes(shippingPlz)) {
                shippingValid = false;
                setShippingPlzError(intl.formatMessage({id: 'postalCode.not.deliverable.plz'}));
            }

        } else {
            shippingValid = true;
            billingValid = validateBillingAddress();
        }

        if(shippingValid && billingValid) {
            const shippingAddressValues: CheckoutAddressValues = {
                salutation: shippingSalutation,
                company: shippingCompany,
                firstName: shippingFirstName,
                lastName: shippingLastName,
                address: shippingAddress,
                address2: shippingAddress2,
                plz: shippingPlz,
                city: shippingCity,
                phone: shippingPhone
            }

            const billingAddressValues: CheckoutAddressValues = {
                salutation: billingSalutation,
                company: billingCompany,
                firstName: billingFirstName,
                lastName: billingLastName,
                address: billingAddress,
                address2: billingAddress2,
                plz: billingPlz,
                city: billingCity,
                phone: billingPhone
            }

            const checkoutAddresses: CheckoutAddressRequest = {
                shippingAddress: shippingAddressValues,
                billingAddress: (shippingAddressEqualsBillingAddress && props.hasShippableFulfillmentGroups) ? shippingAddressValues : billingAddressValues,
                shippingAddressEqualsBillingAddress: shippingAddressEqualsBillingAddress
            }

            let saveCheckoutAddressResponse = await saveCheckoutAddress(checkoutAddresses);
            if(saveCheckoutAddressResponse.status === 'ERROR') {
                setShippingPlzError(saveCheckoutAddressResponse.message);
            } else {
                await dispatch(checkoutActions.fetchCheckoutAddresses());
                await dispatch(checkoutActions.fetchCurrentCheckoutSection());
                await dispatch(checkoutActions.fetchCheckoutDeliveryDates());
                await dispatch(checkoutActions.fetchCheckoutDelivery());
                props.setIsSectionOpen(false);
            }
        }

    }

    const validateShippingAddress = () => {

        let isValid = true;

        if(shippingFirstName) {
            setShippingFirstNameError('')
        } else {
            setShippingFirstNameError(intl.formatMessage({id: 'firstName.required'}));
            isValid = false;
        }

        if(shippingLastName) {
            setShippingLastNameError('')
        } else {
            setShippingLastNameError(intl.formatMessage({id: 'lastName.required'}));
            isValid = false;
        }

        if(shippingCity) {
            setShippingCityError('')
        } else {
            setShippingCityError(intl.formatMessage({id: 'city.required'}));
            isValid = false;
        }

        if(shippingPhone) {
            if(shippingPhone.length > 20 || !isNumeric(shippingPhone.replace(/\s+/g, ''))) {
                setShippingPhoneError(intl.formatMessage({id: 'phone.invalid.checkout'}))
                isValid = false;
            } else {
                setShippingPhoneError('')
            }
        } else {
            setShippingPhoneError(intl.formatMessage({id: 'phone.required'}));
            isValid = false;
        }

        if(shippingSalutation) {
            setShippingSalutationError('')
        } else {
            setShippingSalutationError(intl.formatMessage({id: 'salutation.required'}));
            isValid = false;
        }

        if(shippingAddress) {
            if(isValidAddress(shippingAddress)) {
                setShippingAddressError('')
            } else {
                setShippingAddressError(intl.formatMessage({id: 'addressLine1.invalid'}));
                isValid = false;
            }
        } else {
            setShippingAddressError(intl.formatMessage({id: 'addressLine1.required'}));
            isValid = false;
        }

        if(shippingPlz) {
            if(shippingPlz.length === 4 && /\d{4}/.test(shippingPlz)) {
                setShippingPlzError('');
            } else {
                setShippingPlzError(intl.formatMessage({id: 'postalCode.invalid'}));
                isValid = false;
            }
        } else {
            setShippingPlzError(intl.formatMessage({id: 'postalCode.required'}));
            isValid = false;
        }
        
        return isValid;

    }

    const validateBillingAddress = () => {

        let isValid = true;

        if(!shippingAddressEqualsBillingAddress || !props.hasShippableFulfillmentGroups) {

            if(billingFirstName) {
                setBillingFirstNameError('')
            } else {
                setBillingFirstNameError(intl.formatMessage({id: 'firstName.required'}));
                isValid = false;
            }

            if(billingLastName) {
                setBillingLastNameError('')
            } else {
                setBillingLastNameError(intl.formatMessage({id: 'lastName.required'}));
                isValid = false;
            }

            if(billingCity) {
                setBillingCityError('')
            } else {
                setBillingCityError(intl.formatMessage({id: 'city.required'}));
                isValid = false;
            }

            if(billingPhone) {
                if(billingPhone.length > 20 || !isNumeric(billingPhone.replace(/\s+/g, ''))) {
                    setBillingPhoneError(intl.formatMessage({id: 'phone.invalid.checkout'}))
                    isValid = false;
                } else {
                    setBillingPhoneError('')
                }
            } else {
                setBillingPhoneError(intl.formatMessage({id: 'phone.required'}));
                isValid = false;
            }

            if(billingSalutation) {
                setBillingSalutationError('')
            } else {
                setBillingSalutationError(intl.formatMessage({id: 'salutation.required'}));
                isValid = false;
            }

            if (billingAddress) {
                if (isValidAddress(billingAddress)) {
                    setBillingAddressError('')
                } else {
                    setBillingAddressError(intl.formatMessage({id: 'addressLine1.invalid'}));
                    isValid = false;
                }
            } else {
                setBillingAddressError(intl.formatMessage({id: 'addressLine1.required'}));
                isValid = false;
            }

            if (billingPlz) {
                if (parseInt(billingPlz)) {
                    setBillingPlzError('');
                } else {
                    setBillingPlzError(intl.formatMessage({id: 'postalCode.invalid'}));
                    isValid = false;
                }
            } else {
                setBillingPlzError(intl.formatMessage({id: 'postalCode.required'}));
                isValid = false;
            }

        }
        return isValid;
    }

    return (
        <div className={"tw-ml-2 tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-mt-8 tw-justify-start"}>
            {props.hasShippableFulfillmentGroups && (
                <div className={"tw-flex tw-flex-1 tw-flex-col"}>
                    <div className={"tw-text-3xl tw-font-semibold tw-mb-12"}>
                        <FormattedMessage id={'cart.shippingAddress'}/>
                    </div>
                    <CheckoutAddressForm
                        salutation={shippingSalutation}
                        company={shippingCompany}
                        firstName={shippingFirstName}
                        lastName={shippingLastName}
                        address={shippingAddress}
                        address2={shippingAddress2}
                        plz={shippingPlz}
                        city={shippingCity}
                        phone={shippingPhone}

                        setSalutation={setShippingSalutation}
                        setCompany={setShippingCompany}
                        setFirstName={setShippingFirstName}
                        setLastName={setShippingLastName}
                        setAddress={setShippingAddress}
                        setAddress2={setShippingAddress2}
                        setPlz={setShippingPlz}
                        setCity={setShippingCity}
                        setPhone={setShippingPhone}

                        salutationError={shippingSalutationError}
                        firstNameError={shippingFirstNameError}
                        lastNameError={shippingLastNameError}
                        addressError={shippingAddressError}
                        plzError={shippingPlzError}
                        cityError={shippingCityError}
                        phoneError={shippingPhoneError}

                        setSalutationError={setShippingSalutationError}
                        setFirstNameError={setShippingFirstNameError}
                        setLastNameError={setShippingLastNameError}
                        setAddressError={setShippingAddressError}
                        setPlzError={setShippingPlzError}
                        setCityError={setShippingCityError}
                        setPhoneError={setShippingPhoneError}

                        customerAddresses={props.customerAddresses}
                    />
                    <div className={"tw-flex tw-flex-1 tw-mt-8 tw-pr-0 md:tw-pr-24"}>
                        <div
                            onClick={() => {
                                saveAddresses();
                            }}
                            className={"tw-flex tw-flex-1 tw-border-black tw-bg-black tw-text-white tw-border-2 tw-justify-center tw-py-5 tw-mt-6 " +
                                "hover:tw-cursor-pointer hover:tw-bg-white hover:tw-text-black tw-font-gotham-black tw-italic"}>
                            <FormattedMessage id={"address.commit"}/>
                        </div>
                    </div>
                </div>
            )}
            <div className={`tw-flex tw-flex-1 tw-flex-col tw-pt-12 md:tw-pt-0 ${props.hasShippableFulfillmentGroups ? 'tw-pl-0 md:tw-pl-24' : ''}`}>
                {props.hasShippableFulfillmentGroups &&
                    <div className={"tw-text-3xl tw-font-semibold"}>
                        <FormattedMessage id={'cart.billingAddress'}/>
                    </div>
                }
                {props.hasShippableFulfillmentGroups &&
                    <div className={"tw-mt-4 tw-cursor-pointer tw-ml-2"}
                         onClick={() => setShippingAddressEqualsBillingAddress(!shippingAddressEqualsBillingAddress)}>
                        <input
                            type={"checkbox"}
                            readOnly={true}
                            checked={!shippingAddressEqualsBillingAddress}
                        />
                        <span className={"tw-ml-4"}>
                            <FormattedMessage id={'cart.shippingaddress.not.equals.bllingaddress'}/>
                        </span>
                    </div>
                }
                {(!shippingAddressEqualsBillingAddress || !props.hasShippableFulfillmentGroups) && (
                    <CheckoutAddressForm
                        salutation={billingSalutation}
                        company={billingCompany}
                        firstName={billingFirstName}
                        lastName={billingLastName}
                        address={billingAddress}
                        address2={billingAddress2}
                        plz={billingPlz}
                        city={billingCity}
                        phone={billingPhone}

                        setSalutation={setBillingSalutation}
                        setCompany={setBillingCompany}
                        setFirstName={setBillingFirstName}
                        setLastName={setBillingLastName}
                        setAddress={setBillingAddress}
                        setAddress2={setBillingAddress2}
                        setPlz={setBillingPlz}
                        setCity={setBillingCity}
                        setPhone={setBillingPhone}

                        salutationError={billingSalutationError}
                        firstNameError={billingFirstNameError}
                        lastNameError={billingLastNameError}
                        addressError={billingAddressError}
                        plzError={billingPlzError}
                        cityError={billingCityError}
                        phoneError={billingPhoneError}

                        setSalutationError={setBillingSalutationError}
                        setFirstNameError={setBillingFirstNameError}
                        setLastNameError={setBillingLastNameError}
                        setAddressError={setBillingAddressError}
                        setPlzError={setBillingPlzError}
                        setCityError={setBillingCityError}
                        setPhoneError={setBillingPhoneError}

                        customerAddresses={props.customerAddresses}
                    />
                )}
                {!props.hasShippableFulfillmentGroups && (
                    <div className={"tw-flex tw-flex-1 tw-mt-8 tw-pr-0 md:tw-pr-24"}>
                        <div
                            onClick={() => {
                                saveAddresses();
                            }}
                            className={"tw-flex tw-flex-1 tw-border-black tw-bg-black tw-text-white tw-border-2 tw-justify-center tw-py-5 tw-mt-6 " +
                                "hover:tw-cursor-pointer hover:tw-bg-white hover:tw-text-black tw-font-gotham-black tw-italic"}>
                            <FormattedMessage id={"address.commit"}/>
                        </div>
                    </div>
                )}
            </div>
            {!props.hasShippableFulfillmentGroups && (
                <div className={'tw-flex tw-flex-1'}/>
            )}
        </div>
    );

}