import {ReactElement} from "react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {ProductCardCoreProps, ProductCardMode} from "components/product-card/productCardModel";
import {Badge} from "components/product-card/badges/Badge";
import {PackagingInfoLabel} from "widgets/PackagingInfoLabel";
import {SupplyLimitedSaleBar} from "widgets/supplylimitedsale/SupplyLimitedSaleBar";
import {ActionRow} from "components/product-card/action-rows/ActionRow";
import {LoadablePriceTag} from "components/product-card/PriceTag";
import banner from "../../assets/images/Banner_App_Deal.jpg";
import {useSelector} from "react-redux";
import {i18nSelectors} from "../bundle-entry/i18n/i18nSlice";

export type ProductCardModeOrResponsive = ProductCardMode | 'responsive';

export interface ProductCardProps extends ProductCardCoreProps {
	mode: ProductCardModeOrResponsive
	isGridView?: boolean
}

export function ProductCard(props: ProductCardProps): ReactElement {
	const modeByMediaQuery = useMediaQuery(mediaQueries.sm) ? 'tall' : 'compact';
	const mode: ProductCardMode = props.mode === 'responsive' ? modeByMediaQuery : props.mode;
	switch (mode) {
		case "compact":
			return <ProductCardCompact {...props} />;
		case "tall":
			return <ProductCardTall {...props} />;
	}
}

function ProductCardTall(props: ProductCardCoreProps): ReactElement {
	const selectItemEvent = () => {
		const price = props.product.pricingInfo?.salePrice ? props.product.pricingInfo?.salePrice : props.product.pricingInfo?.retailPrice
		// @ts-ignore
		window.dataLayer.push({ ecommerce: null });
		// @ts-ignore
		window.dataLayer.push({
			event: "select_item",
			ecommerce: {
				items: [
					{
						item_id: props.product.productId,
						item_name: props.product.name,
						discount: props.product.pricingInfo?.discount,
						item_category: props.product.type,
						price: price,
						packaging_info: props.product.packagingInfo.type
					}]
			}
		})
	}

	const blLocale = useSelector(i18nSelectors.getCurrentLocale);
	if(props.product) {
		const productUrl = blLocale === 'fr' && props.product.productUrlFr ? props.product.productUrlFr : blLocale === 'it' && props.product.productUrlIt ? props.product.productUrlIt : props.product.productUrl
		return (
			<div>
				<div className={'tw-pb-2'}>
					{props.appOnlyOffer &&
						<div>
							<img
								className={"tw-w-full"}
								src={banner} alt={"Banner Deal of the Week"}
							/>
						</div>
					}
				</div>
				<div className="tw-relative tw-flex tw-flex-col tw-border-2 tw-border-homeComponentsBg tw-h-desktopCard tw-bg-white">
					{props.product.badge &&
						<Badge badgeType={props.product.badge}
							   pricingInfo={props.product.pricingInfo}
						/>
					}
					<a
						className={`tw-flex-1 tw-flex tw-flex-col tw-items-stretch tw-cursor-pointer ${props.appOnlyOffer ? "tw-mt-2" : "tw-mt-8"}`}
						href={`${productUrl}`}
						onClick={event => {selectItemEvent()}}
					>
						<div className={`tw-w-auto tw-flex ${props.appOnlyOffer ? "tw-p-2" : "tw-p-8"} tw-pt-0 tw-items-center tw-justify-center`}>
							<img
								className="tw-h-productCardImageDesktop tw-w-auto tw-max-w-full tw-object-scale-down"
								src={`${props.product.imgUrl}?preview`}
								alt={props.product.name}
							/>
						</div>
						<div className="tw-flex tw-flex-1 tw-flex-col tw-p-4">
							<div className="tw-text-3xl tw-font-semibold tw-text-black">
								{props.product.name}
							</div>
							<div className="tw-mt-2">
								<PackagingInfoLabel packagingInfo={props.product.packagingInfo}/>
							</div>
						</div>
						<div className={'tw-px-4 tw-mb-4'}>
							{props.product.supplyLimitedSale != null && (
								<SupplyLimitedSaleBar supplyLimitedSale={props.product.supplyLimitedSale}/>
							)}
						</div>
						<LoadablePriceTag mode="tall" pricingInfo={props.product.pricingInfo}/>
					</a>

					<ActionRow
						blackFridayActive={props.blackFridayActive}
						orderId={props.orderId}
						mode='tall'
						product={props.product}
						cartTrackingClass={props.cartTrackingClass}
					/>
				</div>
			</div>
		)
	}
	return <div />;
}

function ProductCardCompact(props: ProductCardCoreProps): ReactElement {
	const hasBadge = (props.product.badge != null && props.product.badge !== 'badge_sale') || props.product.pricingInfo?.isSale;
	const blLocale = useSelector(i18nSelectors.getCurrentLocale);
	const productUrl =  blLocale === 'fr' && props.product.productUrlFr ? props.product.productUrlFr : blLocale === 'it' && props.product.productUrlIt ? props.product.productUrlIt : props.product.productUrl

	return (
		<div className={`tw-relative tw-flex tw-flex-col ${props.isGridView ? "tw-justify-between" : ""} tw-border-2 tw-border-homeComponentsBg tw-my-4`}>
			{props.product.badge &&
				<Badge badgeType={props.product.badge}
					   pricingInfo={props.product.pricingInfo}
				/>
			}

			<a
				className={`tw-flex ${props.isGridView ? "tw-mt-24 tw-flex-col" : hasBadge ? "tw-mt-24 tw-items-center" : "tw-mt-6 tw-items-center"} tw-cursor-pointer`}
			   href={`${productUrl}`}
			>
				<div className={`tw-flex ${props.isGridView ? "tw-items-center" : "tw-w-productCardImage"} tw-justify-center tw-p-8 tw-pt-0`}>
					<img
						className={"tw-h-productCardImageMobile tw-w-auto tw-max-w-full tw-object-scale-down"}
						src={`${props.product.imgUrl}?preview`}
						alt={props.product.name}
					/>
				</div>
				<div className={`tw-flex tw-flex-1 tw-flex-col ${props.isGridView ? "tw-px-5 tw-pb-5" : "tw-pr-4"}`}>
					<div className={`${props.isGridView ? "tw-text-lg" : "tw-text-3xl"} tw-font-semibold tw-text-black product-card-truncate-name`}>
						{props.product.name}
					</div>
					<div>
						<PackagingInfoLabel packagingInfo={props.product.packagingInfo}/>
					</div>
				</div>
			</a>

			{props.product.supplyLimitedSale != null && (
				<div className={`${props.isGridView ? "tw-px-5" : "tw-px-7" } tw-pb-5`}>
					<SupplyLimitedSaleBar supplyLimitedSale={props.product.supplyLimitedSale}/>
				</div>
			)}

			{props.isGridView &&
				<div className={'tw-px-5 tw-pb-5'}>
					<LoadablePriceTag mode="compact" pricingInfo={props.product.pricingInfo}/>
				</div>
			}

			<ActionRow
				blackFridayActive={props.blackFridayActive}
				orderId={props.orderId}
				mode="compact"
				product={props.product}
				cartTrackingClass={props.cartTrackingClass}
				isGridView={props.isGridView}
			>
				{!props.isGridView &&
					<LoadablePriceTag mode="compact" pricingInfo={props.product.pricingInfo}/>
				}
			</ActionRow>
		</div>
	)

}

