const TextBadgeTypes = [
    "badge_new",
    "badge_zeroWaste",
    "badge_saison",
    "badge_limitedEdition",
    "badge_outOfStock"
] as const;
export type TextBadgeType = typeof TextBadgeTypes[number];

const SpecialBadgeTypes = ["badge_sale", "badge_mvp", "badge_cyberSale"] as const;
export type SpecialBadgeType = typeof SpecialBadgeTypes[number];

export type BadgeType = TextBadgeType | SpecialBadgeType;

export function getDisplayText(type: BadgeType): string {
    switch (type) {
        case "badge_new":
            return "New";
        case "badge_zeroWaste":
            return "zero waste";
        case "badge_saison":
            return "Seasonal";
        case "badge_limitedEdition":
            return "Limited Edition";
        case "badge_outOfStock":
            return "Out of Stock";
        case "badge_mvp":
            return "Mövenpick Wein";
        case "badge_sale":
            return "Aktion";
        case "badge_cyberSale":
            return "Crazy Cyber Sale";
    }
}

export function isTextBadge(type: BadgeType): type is TextBadgeType {
    return TextBadgeTypes.includes(type as any);
}
