import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./custom-react-datepicker.css";
import {saveCheckoutDelivery} from "../../../../lib/api/checkoutApi";
import {CheckoutDeliveryModel, SaveCheckoutDeliveryModel} from "../../model/CheckoutDeliveryModel";
import {checkoutActions, checkoutSelectors} from "../../checkoutSlice";
import {useAppDispatch} from "../../../../lib/redux/reduxHooks";
import {CheckoutSectionDeliveryDatePicker} from "./CheckoutSectionDeliveryDatePicker";
import {useSelector} from "react-redux";
import {CheckoutDeliveryDateField} from "./fields/CheckoutDeliveryDateField";
import {CheckoutDeliveryWhereToDeliverField} from "./fields/CheckoutDeliveryWhereToDeliverField";
import {CheckoutDeliveryInstructionField} from "./fields/CheckoutDeliveryInstructionField";
import {CheckoutDeliverySaveButton} from "./fields/CheckoutDeliverySaveButton";
import {CheckoutDeliveryDeposit} from "./fields/CheckoutDeliveryDeposit";
import {CheckoutDeliveryCargoInfo} from "./fields/CheckoutDeliveryCargoInfo";


interface CheckoutSectionDeliveryRowProps {
    setIsSectionOpen: (value: boolean) => void;
    checkoutDelivery: CheckoutDeliveryModel;
    checkoutDeliveryDates: string[];
}

export type WHERE_TO_DELIVER = 'DOOR' | 'FLOOR' | 'GARAGE' | 'OTHER';
export type WHERE_TO_DEPOSIT = 'ENTRY' | 'GARAGE' | 'OTHER';
export type ELEVATOR_TYPE = 'INIT'|'YES'|'NO';

export const floorValues = ['-1', 'EG', '1', '2', '3', '4', '5'];

export function CheckoutSectionDeliveryRow(props: CheckoutSectionDeliveryRowProps) {

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [startDate, setStartDate] = useState<Date|undefined>();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [whereToDeliver, setWhereToDeliver] = useState<undefined | WHERE_TO_DELIVER>(undefined);
    const [whereToDeliverOtherText, setWhereToDeliverOtherText] = useState<string>('');
    const [whereToDeliverOtherInstructionWarning, setWhereToDeliverOtherInstructionWarning] = useState<string>('');
    const [floor, setFloor] = useState<string>('');
    const [depositEnabled, setDepositEnabled] = useState<undefined | boolean>(undefined);
    const [whereToDeposit, setWhereToDeposit] = useState<undefined | WHERE_TO_DEPOSIT>(undefined);
    const [whereToDepositOtherText, setWhereToDepositOtherText] = useState<string>('');
    const [whereToDepositOtherInstructionWarning, setWhereToDepositOtherInstructionWarning] = useState<string>('');
    const [elevator, setElevator] = useState<ELEVATOR_TYPE>('INIT');

    const [whereToDeliverError, setWhereToDeliverError] = useState('');
    const [whereToDepositError, setWhereToDepositError] = useState('');
    const [floorError, setFloorError] = useState('');
    const [elevatorError, setElevatorError] = useState('');
    const [showElevatorSelection, setShowElevatorSelection] = useState(false);
    const [showFloorTextInput, setShowFloorTextInput] = useState(false);

    const [deliveryInstruction, setDeliveryInstruction] = useState<string>('');

    const [depositEnabledError, setDepositEnabledError] = useState<string>();

    useEffect(() => {
        dispatch(checkoutActions.fetchCheckoutKeywords())
    }, [dispatch]);

    useEffect(() => {
        setWhereToDeliver(props.checkoutDelivery.whereToDeliver as WHERE_TO_DELIVER);
        setWhereToDeliverOtherText(props.checkoutDelivery.whereToDeliverOtherInstruction ? props.checkoutDelivery.whereToDeliverOtherInstruction : '');
        setWhereToDeposit(props.checkoutDelivery.whereToDeposit as WHERE_TO_DEPOSIT);
        setWhereToDepositOtherText(props.checkoutDelivery.whereToDepositOtherInstruction ? props.checkoutDelivery.whereToDepositOtherInstruction : '');
        setDepositEnabled(props.checkoutDelivery.depositOrder === null ? undefined : props.checkoutDelivery.depositOrder);
        setElevator(props.checkoutDelivery.elevator === null ? 'INIT' : (props.checkoutDelivery.elevator ? 'YES' : 'NO'));
        setShowElevatorSelection(props.checkoutDelivery.whereToDeliver === 'FLOOR' && props.checkoutDelivery.floorInstruction !== 'EG');
        setDeliveryInstruction(props.checkoutDelivery.deliveryInstruction);

        if(props.checkoutDelivery.whereToDeliver === 'FLOOR') {
            if (floorValues.includes(props.checkoutDelivery.floorInstruction)) {
                setFloor(props.checkoutDelivery.floorInstruction);
                setShowFloorTextInput(false);
            } else {
                setFloor(props.checkoutDelivery.floorInstruction);
                setShowFloorTextInput(true);
            }
        }

    }, [props.checkoutDelivery]);

    const saveDelivery = async () => {
        if(whereToDeliver) {

            if((whereToDeliver === 'FLOOR' && !floor) || floor === 'other') {
                setFloorError(intl.formatMessage({id: 'sevendays.floor.error'}));

            } else {
                if(showElevatorSelection && elevator === 'INIT') {
                    setElevatorError(intl.formatMessage({id: 'sevendays.elevator.error'}));

                } else {
                    if(whereToDeliver === 'OTHER' && !whereToDeliverOtherText) {
                        setWhereToDeliverOtherInstructionWarning(intl.formatMessage({id: "sevendays.deliver.other.empty.warning"}));

                    } else {
                        if(whereToDeliverOtherInstructionWarning) {

                        } else {
                            if(whereToDeposit === 'OTHER' && !whereToDepositOtherText) {
                                setWhereToDepositOtherInstructionWarning(intl.formatMessage({id: "sevendays.deliver.other.empty.warning"}));

                            } else {
                                if(whereToDepositOtherInstructionWarning) {

                                } else {
                                    if (depositEnabled === undefined) {
                                        setDepositEnabledError('deposit enabled error');

                                    } else {
                                        if (depositEnabled && !whereToDeposit) {
                                            setWhereToDepositError(intl.formatMessage({id: 'sevendays.deposit.error'}));

                                        } else {
                                            setWhereToDeliverError('');
                                            setWhereToDepositError('');
                                            setElevatorError('');
                                            setFloorError('');

                                            const saveCheckoutRequest: SaveCheckoutDeliveryModel = {
                                                depositOrder: depositEnabled!,
                                                whereToDeliver: whereToDeliver!,
                                                whereToDeliverOtherInstruction: whereToDeliverOtherText ? whereToDeliverOtherText : undefined,
                                                whereToDeposit: whereToDeposit!,
                                                whereToDepositOtherInstruction: whereToDepositOtherText ? whereToDepositOtherText : undefined,
                                                deliveryDate: startDate ? format(startDate, "yyyy-MM-dd") : format(new Date(deliveryDates[0]), 'yyyy-MM-dd'),
                                                floorInstruction: floor,
                                                elevator: !(showElevatorSelection && elevator === 'NO'),
                                                deliveryInstruction: deliveryInstruction,
                                                cargoCheckout: isCargoDelivery()
                                            }

                                            await saveCheckoutDelivery(saveCheckoutRequest);

                                            // reload page due to changes in elevator / price
                                            // remove once price calculation is also with endpoint
                                            if (props.checkoutDelivery.elevator !== saveCheckoutRequest.elevator) {
                                                window.location.reload();
                                            }

                                            dispatch(checkoutActions.fetchCheckoutDelivery());
                                            dispatch(checkoutActions.fetchCurrentCheckoutSection());
                                            props.setIsSectionOpen(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            setWhereToDeliverError(intl.formatMessage({id: 'sevendays.where.to.deliver.error'}));
        }
    }

    const deliveryDates = useSelector(checkoutSelectors.getCheckoutDeliveryDates);

    const isCargoDelivery = () => props.checkoutDelivery.totalWeight > 250;

    const deliveryDatesState = useSelector(checkoutSelectors.getCheckoutDeliveryDatesState);
    if(deliveryDatesState !== 'FETCHED') {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return(
        <div>
            <CheckoutDeliveryDateField
                startDate={startDate || new Date(props.checkoutDeliveryDates[0])}
                setShowDatePicker={setShowDatePicker}
            />
            <div className={"tw-flex tw-flex-1 tw-mt-8"}>
                {showDatePicker && (
                    <CheckoutSectionDeliveryDatePicker
                        date={startDate!}
                        setDate={setStartDate}
                        hideDatePicker={() => setShowDatePicker(false)}
                    />
                )}
            </div>
            <CheckoutDeliveryWhereToDeliverField
                whereToDeliver={whereToDeliver}
                setWhereToDeliver={(v: WHERE_TO_DELIVER) => setWhereToDeliver(v)}
                whereToDeliverOtherText={whereToDeliverOtherText}
                setWhereToDeliverOtherText={setWhereToDeliverOtherText}
                whereToDeliverOtherInstructionWarning={whereToDeliverOtherInstructionWarning}
                setWhereToDeliverOtherInstructionWarning={setWhereToDeliverOtherInstructionWarning}
                whereToDeliverError={whereToDeliverError}
                setWhereToDeliverError={setWhereToDeliverError}
                floor={floor}
                setFloor={setFloor}
                floorError={floorError}
                setFloorError={setFloorError}
                elevator={elevator}
                setElevator={(v: ELEVATOR_TYPE) => setElevator(v)}
                elevatorError={elevatorError}
                setElevatorError={setElevatorError}
                showElevatorSelection={showElevatorSelection}
                setShowElevatorSelection={setShowElevatorSelection}
                showFloorTextInput={showFloorTextInput}
                setShowFloorTextInput={setShowFloorTextInput}
                floorValues={floorValues}
                isCargoDelivery={isCargoDelivery}
            />
            {isCargoDelivery() &&
                <CheckoutDeliveryCargoInfo />
            }
            <CheckoutDeliveryDeposit
                depositEnabled={depositEnabled}
                setDepositEnabled={setDepositEnabled}
                whereToDeliver={whereToDeliver}
                whereToDeliverOtherText={whereToDeliverOtherText}
                setWhereToDeposit={setWhereToDeposit}
                whereToDeposit={whereToDeposit}
                setWhereToDepositOtherText={setWhereToDepositOtherText}
                whereToDepositOtherText={whereToDepositOtherText}
                setWhereToDepositOtherInstructionWarning={setWhereToDepositOtherInstructionWarning}
                whereToDepositOtherInstructionWarning={whereToDepositOtherInstructionWarning}
                setWhereToDepositError={setWhereToDepositError}
                whereToDepositError={whereToDepositError}
                depositEnabledError={depositEnabledError}
                setDepositEnabledError={setDepositEnabledError}
                isCheckoutView={true}
            />
            <CheckoutDeliveryInstructionField
                deliveryInstruction={deliveryInstruction}
                setDeliveryInstruction={setDeliveryInstruction}
            />
            <CheckoutDeliverySaveButton
                saveDelivery={saveDelivery}
            />
        </div>
    )

}